header {
     background-image: url(../../Images/Header\ BG.png);
}
#errtxt {
     font-size: 25px;
     text-decoration: none;
     margin: 5% 0px;
     place-self: center;
     text-align: center;
}
#errtxt a {
     text-decoration: none;
     color: rgb(240, 217, 17)
}

@media screen and (max-width: 520px) {
     #errtxt {
          font-size: 15px;
          margin: 5%;
     }
}