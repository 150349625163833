.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}
.row {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
  margin: 0px;
}

#loom-companion-mv3 {
  display: none;
}
.row>* { 
  padding-right: 0px;
}