.Navsec {
    width: -webkit-fill-available;
    background-color: transparent;
    margin: 2% 5%;
    border-radius: 20px;
}
.Navsec .navbar-toggler, .Navsec .navbar-toggler:hover, .Navsec .navbar-toggler:active {
    color: #FFFF00;
    border: 2px solid #FFFF00;
}
.Navsec .navbar-toggler-icon {
    color: #FFFF00;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.Navsec .navbar {
    width: 100%;
    padding: 0px;
}
#navimg {
    width: 100px;
}
.Navsec a {
    color: white;
}
.Navsec .navbar-brand {
    width: 20%;
}
#midnav .nav-item a:hover {
    color: #FFFF00;
}
#midnav .nav-item a:active {
    color: #FFFF00;
}
#midnav .nav-item a:focus {
    color: #FFFF00;
}
#midcov {
    justify-content: center;
}
#midnav {
    justify-content: center;
    color: white;
    font-size: 18px;
    width: 70%;
}
#endnav {
    justify-content: end;
    width: 30%;
}
#endnav #prt:hover {
    color: #FFFF00;
}
#endnav button {
    background-color: #B7410E;
    color: white;
    border-radius: 10px;
    /* padding: 4% 5%; */
    height: 50px;
    text-align: center;
} 
#endnav button:hover {
    background-color: #FFFF00;
}
#endnav button a:hover {
    color: black;
}
.navbar-toggler {
    border: #20486E 2px solid;
    color: #20486E;
}
.navbar-toggler:active {
    border: #20486E 2px solid;
    color: #20486E;
}
.navbar-toggler:hover {
    border: 4px #20486E solid;
}
.navbar-collapse {
    text-align: -webkit-center;
}
.show li {
    padding: 2%;
}
.show #endnav {
    width: 100%;
}
.show #endnav button {
    margin: 2%;
    /* padding: 4% 5%; */
}
@media screen and (min-width: 900px) {
    #endnav button {
        padding: 2% 5%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1490px) {
    #midcov {
        width: 80%;
    }
    #endnav {
        width: 20%;
    }
    #navbarNav ul a {
        font-size: 15px;
    }
}

@media screen and (min-width: 521px) and (max-width: 991px) {
    .Navsec .container-fluid {
        padding: 0px 2%;
    }
}
@media screen and (max-width: 520px) {
    .Navsec {
        padding: 0px 5%;
    }
    #navimg {
        width: 70px;
    }
    .navbar-collapse {
        padding: 5%;
    }
}

@media screen and (max-width: 521px) {
    .navbar-collapse {
        background-color: black;
    }
}